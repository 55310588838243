class Listen {
    static async queryUrl(url) {
        return fetch(url, {
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            },
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            return response.json().then((json) => {
                throw json;
            });
        });
    }

    constructor(latestMusic) {
        const url = `/listen/latest.json`;
        Listen.queryUrl(url).then((result) => {
            if (result.title !== undefined && result.title !== null) {
                const emoji = document.querySelector('span.latest-music-emoji');
                if(emoji) {
                    emoji.classList.remove('js-hidden');
                }
                latestMusic.appendChild(document.createTextNode(result.title));
            }
        });
    }

    static init() {
        const latestMusic = document.querySelector('span.latest-music');
        if (latestMusic) {
            // eslint-disable-next-line no-new
            new Listen(latestMusic);
        }
    }
}

export default Listen;